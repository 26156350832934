/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('materialize-css/dist/css/materialize.min.css');
const M = require('materialize-css/dist/js/materialize.min.js');
require('@fortawesome/fontawesome-free/css/all.min.css')
require('@fortawesome/fontawesome-free/js/all.min.js')
require('../scss/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

M.AutoInit();
console.log('Hello Webpack Encore! Edit me in assets/js/app.js');


var app = {
    init: function() {
      var elems = document.querySelectorAll('.datepicker');
      const options = {
        format: 'dd-mm-yyyy',
        firstDay: 1,
        yearRange: 100,
        i18n: {
          months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Decembre"],
          monthsShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec"],
          weekdays: ["Dimanche","Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
          weekdaysShort: ["Dim","Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
          weekdaysAbbrev: ["D","L", "M", "M", "J", "V", "S"],
          cancel: 'Annuler',
          clear: 'Effacer',
          today: 'aujourd\'hui',
          done: 'Ok',
        },
      };
      
      var instance = M.Datepicker.init(elems, options );;

      //collapsible
      var largeur = window.innerWidth;
      var accordions = document.querySelectorAll('.container-accordion');
      //si inf a 850 alors collapsible
      for (var i=0;i<accordions.length;i++) {
        var instance = M.Collapsible.init(accordions[i]);
        if (largeur < 851) {
          accordions[i].classList.add("collapsible");
          instance.close();
        } else {
          instance.open();
        }
      }
      
      window.addEventListener("resize", function(){
        if(window.innerWidth < 851){
           for (var i=0;i<accordions.length;i++) {
              accordions[i].classList.add("collapsible");
              var instance = M.Collapsible.init(accordions[i]);
              instance.close();
            }
        } else {
          for (var i=0;i<accordions.length;i++) {
            var instance = M.Collapsible.getInstance(accordions[i]);
            if(instance) {
              instance.open();
              instance.destroy();
            }
            accordions[i].classList.remove("collapsible");
          }
        }
      });


      //menu
      var arrow = document.getElementById('arrow-top');
      const windowY = window.scrollY;

      let scrollPos = 0;
      let scrollPos2 = 50;
      const nav = document.getElementById('header');
      function checkPosition() {
          let windowY = window.scrollY;
          if (windowY < scrollPos) {
            // Scrolling UP
            if (scrollPos2<windowY) {
              nav.classList.add('is-visible');
              nav.classList.remove('is-hidden');
            } else {
              nav.classList.add('is-visible');
              nav.classList.remove('is-hidden');
            }
          } else if (windowY > scrollPos && windowY > 90){
            // Scrolling DOWN
            nav.classList.add('is-hidden');
            nav.classList.remove('is-visible');
          }
          scrollPos = windowY;
          
          if (windowY > 0) {
            arrow.classList.remove('hidden_arrow');
            
          } else {
            arrow.classList.add('hidden_arrow');
          }
        }
        window.addEventListener('scroll', checkPosition);


        //change footer fixed to absolute quand on arrive au footer

/*         var fixedSide = document.querySelector('#fixed-side');
        var footer = document.querySelector('#footer');
        document.addEventListener("scroll", function(){
          checkOffset();
        });
        
        function checkOffset() {
          function getRectTop(el){
            var rect = el.getBoundingClientRect();
            return rect.top;
            
          }
          
          if((getRectTop(fixedSide) + document.body.scrollTop) + fixedSide.offsetHeight >= (getRectTop(footer) + document.body.scrollTop) - 20)
            fixedSide.classList.add('absolute-bottom');
            
          if(document.body.scrollTop + window.innerHeight < (getRectTop(footer) + document.body.scrollTop))
            fixedSide.classList.remove('absolute-bottom');

        } */


    }
}

app.init();

function scrollProgress (element) {
  const progress = document.querySelector(element)
  const path = progress.querySelector('path')
  const length = path.getTotalLength()

  progress.setAttribute('aria-valuemax', `100%`)
  progress.setAttribute('aria-valuenow', `0%`)
  progress.setAttribute('role', `progress`)
  path.setAttribute('aria-hidden', `true`)

  const scrollable = () => {
    let top = document.body.scrollTop || document.documentElement.scrollTop
    let height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    let scroll = parseFloat(top / height * length)
    let value = parseFloat(top / height * 100).toFixed(0)
    path.setAttribute('stroke-dashoffset', -(length - scroll))
    progress.setAttribute('aria-valuenow', `${value}%`)
  }

  window.addEventListener('scroll', scrollable)
}

scrollProgress('.scroll-progress-circle');
